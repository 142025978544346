// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60022: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { text1 },
    },
  } = themeVars;
  return {
    PromotionsWrapper: {
      promotionsWrapper: {
        padding: [0, margins.lg],
      },
    },
    PromotionsComponent: {
      // selectedLink: {
      //   color: `${text2} !important`,
      //   borderBottom: `3px solid ${text2} !important`,
      //   '&:hover': {
      //     opacity: `1 !important`,
      //   },
      // },
      promoLink: {
        color: text1,
        '&:hover, &:active': {
          color: text1,
          opacity: 0.7,
          borderBottom: `3px solid ${text1}`,
        },
      },
    },
  };
};
