import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60022: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  return {
    AccountPageDesktop: {
      accountPageRightContent: {
        background: '#fff',
        boxShadow: '3px 0px 10px rgba(0, 0, 0, 0.1)',
      },
    },
  };
};
