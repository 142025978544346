// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses60022: JSSThemeDefaultBonusesType = (themeVars) => {
  const {
    margins,
    colors: {
      colorCombinations: { whitePrimary, whiteBg1, whiteAccent },
      colorTypes: { text4, background1, white },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        color: white,
        background: background1,
        transition: '.3s ease-in',
        fontWeight: 'bold',
        '&:hover, &.active': whitePrimary,
      },
      tabsItemMobile: {
        ...whiteBg1,
        borderRight: `none`,
        fontWeight: 'bold',
        '&.active': {
          ...whiteAccent,
        },
      },
      bonusContainer: {
        background: text4,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      root: {
        padding: [margins.md, margins.xm],
      },
    },
  };
};
