// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino60022: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, background1, primary, text2 },
      colorCombinations: { whitePrimary, whiteText3 },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: white,
      },
      menuItem: {
        color: primary,
        justifyContent: 'center',
        borderRight: 'none',
        flex: 1,
        fontWeight: 'bold',
        '&.active, &:hover': {
          ...whitePrimary,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            ...whitePrimary,
          },
        },
        '@media (pointer: coarse)': {
          '&:hover, &:active': {
            color: white,
          },
        },
      },
      iconChevron: {
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
        color: text2,
      },
      inputSearch: {
        color: text2,
        '&::placeholder': {
          opacity: 0.5,
          color: text2,
        },
      },
      iconSearch: {
        opacity: 0.5,
      },
      searchResult: {
        padding: [0, margins.md],
      },
      searchResultHeading: {
        color: white,
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: background1,
      },
      providersFilters: {
        '&.active': {
          ...whiteText3,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteText3,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
    },
    CasinoGameLink: {
      rootReal: {
        fontWeight: 'bold',
      },
      rootFun: {
        fontWeight: 'bold',
      },
    },
  };
};
