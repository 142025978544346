// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeHeader60022 } from './JSSThemeHeader60022';
import { JSSThemeSportsMenu60022 } from './JSSThemeSportsMenu60022';
import { JSSThemeHeaderMenu60022 } from './JSSThemeHeaderMenu60022';
import { JSSThemeAuthForm60022 } from './JSSThemeAuthForm60022';
import { JSSThemeBalance60022 } from './JSSThemeBalance60022';
import { JSSThemeBetslip60022 } from './JSSThemeBetslip60022';
import { JSSThemeMyBets60022 } from './JSSThemeMyBets60022';
import { JSSThemeForm60022 } from './JSSThemeForm60022';
import { JSSThemeBannerItems60022 } from './JSSThemeBannerItems60022';
import { JSSThemeHeaderMobile60022 } from './JSSThemeHeaderMobile60022';
import { JSSThemeBonuses60022 } from './JSSThemeBonuses60022';
import { JSSThemeOutcomes60022 } from './JSSThemeOutcomes60022';
import { JSSThemeCoupons60022 } from './JSSThemeCoupons60022';
import { JSSThemeCasino60022 } from './JSSThemeCasino60022';
import { JSSThemePopularLeagues60022 } from './JSSThemePopularLeagues60022';
import { JSSThemeSearch60022 } from './JSSThemeSearch60022';
import { JSSThemeLive60022 } from './JSSThemeLive60022';
import { JSSThemeAccountSection60022 } from './JSSThemeAccountSection60022';
import { JSSThemePromotions60022 } from './JSSThemePromotions60022';
import { JSSThemeSitemap60022 } from './JSSThemeSitemap60022';
import { JSSThemeOryx60022 } from './JSSThemeOryx60022';
import { JSSThemeAffiliates60022 } from './JSSThemeAffiliates60022';

export const colorTypes60022: ColorTypes = {
  ...colorTypes,
  primary: '#a20000',
  text1: '#666666',
  text2: '#19191a',
  text3: '#6e6e6e',
  text4: '#e5ebf0',
  text5: '',
  background1: '#3d6d99',
  background2: '#e8f0fe',
  background3: '#b9bcc3',
  background4: '#182d3d',
  background5: '#19191a',
  background6: '#19191a', //available for a new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#cc0001',
  success: '#14a33c',
  error: '#b33322',
  error2: '#b33322',
  error3: '#b33322',
  warning: '#ff6308',
  inform: '#8ecb65',
  neutral1: '#1d1d1c',
  neutral2: '#042048',
  neutral3: '#2c4761',
  neutral4: '#374e60',
  border1: '#3d6d99',
  border2: '#add8e6',
};

const pick = ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteNeutral2,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
  whiteBg1,
  text2Bg3,
}) => ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteNeutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
  whiteBg1,
  text2Bg3,
});

export const colorCombinations60022 = getColorCombinations(
  colorTypes60022,
  pick
);

export const JSSThemeVariables60022: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-italic-webfont',
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bolditalic-webfont',
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-black-webfont',
          fontWeight: '900',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-blackitalic-webfont',
          fontWeight: '900',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-light-webfont',
          fontWeight: '200',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes60022,
    colorCombinations: colorCombinations60022,
  },
};

export const JSSTheme60022: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteAccent,
        whitePrimary,
        whiteBg4,
        text2Bg2,
        whiteBg1,
      },
      colorTypes: {
        border2,
        background2,
        background4,
        black,
        white,
        primary,
        text2,
        text4,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    Heading: {
      common: {
        background: text4,
        padding: [margins.md, margins.xm, margins.xs],
        margin: [0, margins.xs],
      },
    },
    affiliates: JSSThemeAffiliates60022(themeVars),
    headerMenu: JSSThemeHeaderMenu60022(themeVars),
    sportsMenu: JSSThemeSportsMenu60022(themeVars),
    authForm: JSSThemeAuthForm60022(themeVars),
    bonuses: JSSThemeBonuses60022(themeVars),
    header: JSSThemeHeader60022(themeVars),
    balance: JSSThemeBalance60022(themeVars),
    betslip: JSSThemeBetslip60022(themeVars),
    mybets: JSSThemeMyBets60022(themeVars),
    outcomes: JSSThemeOutcomes60022(themeVars),
    form: JSSThemeForm60022(themeVars),
    banners: JSSThemeBannerItems60022(themeVars),
    headerMobile: JSSThemeHeaderMobile60022(themeVars),
    coupons: JSSThemeCoupons60022(themeVars),
    casino: JSSThemeCasino60022(themeVars),
    popularLeagues: JSSThemePopularLeagues60022(themeVars),
    search: JSSThemeSearch60022(themeVars),
    live: JSSThemeLive60022(themeVars),
    accountSection: JSSThemeAccountSection60022(themeVars),
    oryx: JSSThemeOryx60022(themeVars),
    LiveCalendar: {
      common: {
        margin: '0',
      },
      dateTitle: {
        color: black,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
        },
      },
      link: {
        color: black,
      },
    },
    Button: {
      root: {
        borderRadius: '2px',
        fontWeight: 'bold',
      },
      default: getButtonColors(whiteAccent),
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg1),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkAccent: {
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            color: border2,
          },
        }),
      },
    },
    NoBetsMessage: {
      root: {
        ...text2Bg2,
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: 'none',
        ...whiteBg4,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotential: {
        color: text2,
      },
      stakePotentialWrapper: {
        border: `1px solid ${background4}`,
        borderTop: 'none',
        background: white,
        marginTop: 0,
        padding: margins.md,
      },
      stakeTitle: {
        marginTop: 0,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        margin: 0,
        background: background2,
        borderRadius: 0,
      },
      singleSelectionWrapper: {
        borderTop: 'none',
        marginTop: 0,
        paddingTop: 0,
      },
    },
    promotions: JSSThemePromotions60022(themeVars),
    sitemap: JSSThemeSitemap60022(themeVars),
  };
};

export const getJSSThemePatterns60022: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent, whiteBg3 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg3),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
