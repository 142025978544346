// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60022,
  JSSThemeVariables60022,
  getJSSThemePatterns60022,
} from './JSSTheme60022';
import { GeoblockPage60022 } from './GeoblockPage60022';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60022').then((module) => module.FullDesktop60022),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60022').then((module) => module.FullMobile60022),
  }),
  JSSTheme: JSSTheme60022,
  JSSThemeVariables: JSSThemeVariables60022,
  JSSThemePatterns: getJSSThemePatterns60022,
  GeoblockPage: GeoblockPage60022,
});
