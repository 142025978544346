// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons60022: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: {
        black,
        neutral3,
        white,
        background4,
        neutral2,
        primary,
        text4,
      },
    },
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportHeader: {
        color: black,
        '&:first-child': {
          marginTop: margins.xs,
        },
      },
      sportMobileWrap: {
        background: white,
      },
    },
    SportTabs: {
      tabs: {
        padding: [0, margins.xm, margins.xm],
      },
      tab: {
        background: white,
        color: background4,
        '&:hover': {
          color: white,
        },
      },
      activeTab: {
        color: white,
      },
    },
    WidgetSportMobile: {
      league: {
        color: black,
        '&>a': {
          color: black, // TODO remove since scss is deleted
        },
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      teams: {
        color: neutral3,
      },
      teamItem: {
        color: neutral3,
      },
      date: {
        color: neutral3,
      },
    },
    Market: {
      root: {
        borderTop: 0,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: 'transparent',
        color: neutral2,
        fontWeight: 'bold',
        '&:hover': {
          color: primary,
          background: 'transparent',
        },
      },
    },
    WidgetSport: {
      event: {
        margin: margins.md,
      },
    },
    PrematchEvent: {
      marketRoot: {
        background: `${text4} !important`,
        borderTop: 'none !important',
      },
      marketNameHeader: {
        background: `${text4} !important`,
      },
    },
    Betbuilder: {
      exampleContainer: {
        background: white,
      },
    },
  };
};
