// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates60022: JSSThemeDefaultAffiliatesType = (
  themeVars
) => {
  const { margins } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHome: {
      affiliateRoot: {
        background: '#e5ebf0',
      },
      topBannerHeader: {
        fontSize: '48px',
        maxWidth: '450px',
        fontWeight: '800',
        lineHeight: '62px',
        '@media (pointer: coarse)': {
          fontSize: '24px',
          lineHeight: '34px',
        },
      },
      topBannerButton: {
        borderRadius: 6,
        color: '#fff',
        backgroundColor: '#a20000',
        fontWeight: 'normal',
        boxShadow: 'none',
        backgroundSize: 'none',
        background: '#a20000',
        textShadow: 'none',
        '&:hover': {
          backgroundColor: '#d50505',
          backgroundPosition: 0,
          boxShadow: 'none',
          color: '#fff',
        },
        '&:disabled:hover': {
          backgroundColor: '#a20000',
          background: '#a20000',
          boxShadow: 'none',
        },
      },
      stepNumber: {
        background: '#a20000',
      },
      step: {
        boxShadow: '0px 8px 21px 14px rgb(24 45 61 / 14%)',
      },
      productsButton: {
        borderRadius: 6,
        color: '#fff',
        backgroundColor: '#a20000',
        fontWeight: 'normal',
        boxShadow: 'none',
        backgroundSize: 'none',
        background: '#a20000',
        textShadow: 'none',
        '&:hover': {
          backgroundColor: '#d50505',
          backgroundPosition: 0,
          boxShadow: 'none',
          color: '#fff',
        },
        '&:disabled:hover': {
          backgroundColor: '#a20000',
          background: '#a20000',
          boxShadow: 'none',
        },
      },
      productsRoot: {
        background: '#182d3d',
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: '#fff',
      },
      loginBarRoot: {
        background: '#3d6d99',

        '@media (pointer: coarse)': {
          background: 'transparent',
          fontSize: 18,
        },
      },
      reasonsIcon: {
        marginRight: '25px',
        minWidth: '35px',
        fill: '#3d6d99',
      },
      SignupRoot: {
        color: '#fff',
        '&:hover': {
          opacity: 1,
          color: '#fff',
        },
      },
      SignupIcon: {
        fill: '#fff',
      },
      topBlock: {
        marginTop: 10,
        paddingBottom: 10,
        '@media (pointer: coarse)': {
          margin: '0px auto 0',
          padding: '10px 0 10px 0',
        },
      },
      topMenuItem: {
        color: '#19191a',
        '&:hover': {
          color: '#a20000',
        },
        '&:active, &:focus': {
          color: '#a20000',
        },
        '@media (pointer: coarse)': {
          color: '#fff',
          fontSize: 18,
        },
      },
      topMenuItemActive: {
        color: '#a20000',
      },
      burgerOverlay: {
        background: '#042048',
      },
      burgerBar: {
        background: '#042048',
      },
    },
    affiliatesHeading: {
      color: '#042048',
    },
    affiliatesSignup: {
      signupRoot: {
        maxWidth: 800,
        margin: '50px auto',
        boxShadow: '0px 8px 21px 14px rgb(24 45 61 / 14%)',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 900px)': {
          width: '100%',
          maxWidth: '100%',
          boxShadow: 'none',
        },
      },
    },
    affiliatesFooter: {
      footerRoot: {
        background: '#000',
      },
      productsTerms: {
        color: '#fff',
        '&:hover': {
          color: '#c2c2c2',
        },
      },
    },
    affiliatesFAQ: {
      contactsMail: {
        color: 'rgba(4, 26, 61, 1)',
        fontWeight: 700,
        '&:hover': {
          color: '#a20000',
        },
      },
    },
    affiliatesProducts: {
      productsButton: {
        borderRadius: 6,
        color: '#fff',
        backgroundColor: '#a20000',
        fontWeight: 'normal',
        boxShadow: 'none',
        backgroundSize: 'none',
        background: '#a20000',
        textShadow: 'none',
        '&:hover': {
          backgroundColor: '#d50505',
          backgroundPosition: 0,
          boxShadow: 'none',
          color: '#fff',
        },
        '&:disabled:hover': {
          backgroundColor: '#a20000',
          background: '#a20000',
          boxShadow: 'none',
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        color: '#182d3d',
        '&:hover': {
          color: '#a20000',
        },
      },
      contactsContent: {
        minHeight: 'calc(100vh - 300px)',
      },
    },
  };
};
