// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance60022: JSSThemeDefaultBalanceType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorCombinations: { whitePrimary },
      colorTypes: { background4, background1, white, neutral4, primary },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        padding: [margins.xs, margins.md],
        background: background1,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        color: white,
        fontWeight: 'bold',
        '&:hover': {
          background: background1,
        },
      },
      userMenuWrap: {
        padding: 0,
        background: background4,
        '&:before': {
          background: background4,
        },
      },
      accountMenuWrap: {
        borderBottom: `1px solid ${neutral4}`,
        borderTop: `1px solid ${neutral4}`,
      },
      balanceWrap: {
        display: 'flex',
        justifyContent: 'center',
      },
      logoutWrap: {
        color: white,
        fontWeight: 'bold',
        padding: margins.md,
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      },
    },
    UsernameCompanent: {
      userName: {
        paddingTop: margins.md,
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
      accountNumber: {
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
      },
      bonusTitle: {
        color: white,
      },
      bonusValue: {
        color: primary,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        color: primary,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        padding: [margins.md],
        color: white,
        fontWeight: 300,
        '&:hover': {
          ...whitePrimary,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        height: '100%',
        background: background4,
      },
      userMenuItem: {
        color: white,
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          ...whitePrimary,
        },
      },
    },
  };
};
